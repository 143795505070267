import AButton from "aloha-vue/src/AButton/AButton";
import PuxTranslate from "../PuxTranslate/PuxTranslate.vue";

import translate from "../../directives/translate";
import init from "../../directives/init";
import {
  keyBy,
  isString,
  forEach,
  isNil,
  size,
  isPlainObject,
  isArray,
} from "lodash-es";

// @vue/component
export default {
  name: "Alert",
  components: {
    AButton,
    PuxTranslate,
  },
  directives: {
    translate,
    init,
  },
  props: {
    errors: {
      type: Object,
      default: () => ({}),
    },
    labels: {
      type: Object,
      default: undefined,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: undefined,
    },
    type: {
      type: String,
      required: false,
      default: undefined,
    },
    focusByInit: {
      type: Boolean,
      required: false,
    },
    onDismiss: {
      type: Function,
      default: undefined,
    },
    dismissible: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    flattenedErrors() {
      const errs = {};
      if (isNil(this.errors)) {
        return errs;
      }
      forEach(this.errors, (err, key) => {
        errs[key] = this.flattenErrors(err);
      });
      return errs;
    },

    labeledErrors() {
      const errs = {};
      if (isNil(this.flattenedErrors)) {
        return errs;
      }
      forEach(this.flattenedErrors, (err, key) => {
        const lbl = this.getLabel(key);
        if (!isNil(lbl) && size(lbl) > 0) {
          errs[lbl] = err;
        }
      });
      return errs;
    },

    generalErrors() {
      const errs = [];
      if (isNil(this.flattenedErrors)) {
        return errs;
      }
      forEach(this.flattenedErrors, (err, key) => {
        const lbl = this.getLabel(key);
        if (isNil(lbl) || size(lbl) === 0) {
          errs.push(err);
        }
      });
      return errs;
    },

    classParent() {
      let classParent = "";
      if (this.typeLocal) {
        classParent = `alert alert-${ this.typeLocal }`;
      }
      if (this.dismissible && this.onDismiss) {
        classParent = `${ classParent } a_pr_6`;
      }
      return classParent;
    },

    getLabelObj() {
      return keyBy(this.list || this.options.list, "id") || {};
    },

    getLabel() {
      return key => {
        if (this.labels && this.labels[key]) {
          return this.labels[key];
        }
        if (this.options.labels && this.options.labels[key]) {
          return this.options.labels[key];
        }
        if (this.getLabelObj[key]) {
          return this.getLabelObj[key].label;
        }
        return "";
      };
    },

    typeLocal() {
      return this.type || this.options.type || "danger";
    },

    getMessage() {
      return this.options.message || "_MSG_ERROR_";
    },

    isSlot() {
      return !!this.$slots.default;
    },

    statusGeneralErrors() {
      return this.showErrors(this.generalErrors);
    },

    statusLabeledErrors() {
      return this.showErrors(this.labeledErrors);
    },

    attributesLocal() {
      if (this.focusByInit) {
        return {
          tabindex: -1,
        };
      }
      return {};
    },
  },
  methods: {
    initAlert() {
      setTimeout(
        () => {
          if (this.focusByInit && this.$refs.alert) {
            this.$refs.alert.focus();
          }
        }
      );
    },

    isString(error) {
      return isString(error);
    },

    showErrors(errors) {
      return size(errors) > 0;
    },

    flattenErrors(errors) {
      const errs = [];
      if (isPlainObject(errors) || isArray(errors)) {
        forEach(errors, err => {
          errs.push(...this.flattenErrors(err));
        });
      } else {
        errs.push(errors);
      }
      return errs;
    }
  },
};
