import {
  addNotification,
} from "aloha-vue/src/compositionAPI/ANotificationAPI";

import {
  abortHttp,
  postHttp,
} from "aloha-vue/src/compositionAPI/AHttpAPI";


import {
  getUser,
} from "./global/compositionAPI/UserAPI";

import {
  get,
  isObject,
  isString,
  startsWith,
} from "lodash-es";

function makeParams({ error, currentRoute, overrideNextUrl }) {
  const PARAMS = {};
  const EXISTING_NEXT_PARAM = get(currentRoute, "query.next");
  if (!EXISTING_NEXT_PARAM) {
    if (overrideNextUrl) {
      PARAMS.next = overrideNextUrl;
    } else {
      PARAMS.next = currentRoute.fullPath;
    }
  }
  if (error.headers["X-Sentry-ID"]) {
    PARAMS.sentryId = error.headers["X-Sentry-ID"];
  }
  return PARAMS;
}

function redirectOnError({ error, showError, redirectTo, $redirect, currentRoute, overrideNextUrl }) {
  const CURRENT_ROUTE_NAME = currentRoute?.name;
  if (redirectTo === CURRENT_ROUTE_NAME) {
    return;
  }
  const PARAMS = makeParams({ error, currentRoute, overrideNextUrl });
  $redirect({
    name: redirectTo,
    query: PARAMS,
  });
  return showError;
}

export default function({ router }) {
  return {
    401: ({ showError, error }) => {
      getUser({ isReload: true }).then(() => {
        if (error.data && error.data.authenticated && !error.data.verified) {
          if (window.location.href.indexOf("/mfa_verifizieren/") === -1) {
            router.push(`/mfa_verifizieren/?next=${ window.location.pathname }${ window.location.search }`);
          }
        } else if (error.data && error.data.mfa_redirect) {
          if (window.location.href.indexOf(error.data.mfa_redirect) === -1) {
            router.push(`${ error.data.mfa_redirect }?next=${ window.location.pathname }${ window.location.search }`);
          }
        } else if (window.location.href.indexOf("/login/") === -1) {
          const loginRoute = localStorage.getItem("loginRoute", "root.login");
          const loginRouteParams = JSON.parse(localStorage.getItem("loginRouteParams", {}));
          const loginRouteQuery = {
            m: "session_expired",
            next: `${ window.location.pathname }${ window.location.search }`
          };
          router.push({
            name: loginRoute,
            params: loginRouteParams,
            query: loginRouteQuery,
          });
        }
      });
      return showError;
    },
    403: ({ showError, error }) => {
      if (error && isObject(error.data) &&
        isString(error.data.detail) &&
        startsWith(error.data.detail, "CSRF Failed")) {
        // entferne gespeicherte Tokens um clientseitig auszuloggen
        postHttp({
          url: "auth/logout/",
        }).then(
          () => {
            const loginRoute = localStorage.getItem("loginRoute", "root.login");
            const loginRouteParams = JSON.parse(localStorage.getItem("loginRouteParams", {}));
            const loginRouteQuery = {
              m: "session_expired",
              next: `${ window.location.pathname }${ window.location.search }`
            };
            router.push({
              name: loginRoute,
              params: loginRouteParams,
              query: loginRouteQuery,
            });
          },
          () => {
            addNotification({
              text: "_MSG_HTTPERRORS_403_ERROR_LOGOUT_",
              type: "error",
            });
          }
        );
        // Fehler muss nicht mehr von Aufrufer verarbeitet werden
        return showError;
      }
      addNotification({
        text: "_MSG_HTTPERRORS_403_ERROR_",
        type: "error",
      });
      return true;
    },
    404: ({ showError, error }) => {
      abortHttp({
        all: true,
        excludeAbortGroup: ["global"],
      });
      const ERROR_ROUTE_NAME = "root.errors.404";
      redirectOnError({
        error,
        showError,
        redirectTo: ERROR_ROUTE_NAME,
        $redirect: router.push,
        currentRoute: router.currentRoute.value,
        overrideNextUrl: window.location.href,
      });
      return showError;
    },
    429: ({ showError, error }) => {
      if (error.data.detail) {
        addNotification({
          text: error.data.detail,
          type: "error",
        });
      } else {
        addNotification({
          text: "_MSG_HTTPERRORS_429_ERROR_MAX_TRIES_",
          type: "error",
        });
      }
      return showError;
    },
    500: ({ showError, error }) => {
      abortHttp({
        all: true,
        excludeAbortGroup: ["global"],
      });
      const ERROR_ROUTE_NAME = "root.errors.500";
      redirectOnError({
        error,
        showError,
        redirectTo: ERROR_ROUTE_NAME,
        $redirect: router.push,
        currentRoute: router.currentRoute.value,
      });
      return showError;
    },
    501: ({ showError, error }) => {
      abortHttp({
        all: true,
        excludeAbortGroup: ["global"],
      });
      const ERROR_ROUTE_NAME = "root.errors.501";
      redirectOnError({
        error,
        showError,
        redirectTo: ERROR_ROUTE_NAME,
        $redirect: router.push,
        currentRoute: router.currentRoute.value,
      });
      return showError;
    },
    502: ({ showError, error }) => {
      abortHttp({
        all: true,
        excludeAbortGroup: ["global"],
      });
      const ERROR_ROUTE_NAME = "root.errors.502";
      redirectOnError({
        error,
        showError,
        redirectTo: ERROR_ROUTE_NAME,
        $redirect: router.push,
        currentRoute: router.currentRoute.value,
      });
      return showError;
    },
    503: ({ showError, error }) => {
      abortHttp({
        all: true,
        excludeAbortGroup: ["global"],
      });
      const ERROR_ROUTE_NAME = "root.errors.503";
      redirectOnError({
        error,
        showError,
        redirectTo: ERROR_ROUTE_NAME,
        $redirect: router.push,
        currentRoute: router.currentRoute.value,
      });
      return showError;
    },
    504: ({ showError, error }) => {
      abortHttp({
        all: true,
        excludeAbortGroup: ["global"],
      });
      const ERROR_ROUTE_NAME = "root.errors.504";
      redirectOnError({
        error,
        showError,
        redirectTo: ERROR_ROUTE_NAME,
        $redirect: router.push,
        currentRoute: router.currentRoute.value,
      });
      return showError;
    },
    all: ({ error, showError }) => {
      if (error.status > 504 && error.status <= 599) {
        abortHttp({
          all: true,
          excludeAbortGroup: ["global"],
        });
        const ERROR_ROUTE_NAME = "root.errors.50*";
        redirectOnError({
          error,
          showError,
          redirectTo: ERROR_ROUTE_NAME,
          $redirect: router.push,
          currentRoute: router.currentRoute.value,
        });
        return showError;
      } else if (error.status > 400 && error.status <= 499) {
        abortHttp({
          all: true,
          excludeAbortGroup: ["global"],
        });
        const ERROR_ROUTE_NAME = "root.errors.40*";
        redirectOnError({
          error,
          showError,
          redirectTo: ERROR_ROUTE_NAME,
          $redirect: router.push,
          currentRoute: router.currentRoute.value,
        });
        return showError;
      }
      return true;
    },
  };
}
