import PuxTranslate from "../PuxTranslate/PuxTranslate.vue";
import TaskLogStatus from "./TaskLogStatus/TaskLogStatus.vue";
import TaskLogProgress from "./TaskLogProgress/TaskLogProgress.vue";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";
import NotificationAPI from "../../compositionAPI/NotificationAPI";

import { gettext } from "../../functions/utils";

import {
  forEach,
  extend,
  get,
  isArray,
  isFunction,
  isObject,
  isString,
  values,
} from "lodash-es";

// @vue/component
export default {
  name: "TaskLog",
  components: {
    PuxTranslate,
    TaskLogProgress,
    TaskLogStatus,
  },
  props: {
    task: {
      type: String, // Task ID
      default: undefined,
    },
    showMsg: {
      type: Boolean,
      default: true
    },
    showProgress: {
      type: Boolean,
      default: true
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    onError: {
      type: Function,
      default: () => ({}),
    },
    onFailure: {
      type: Function,
      default: undefined,
    },
    onFinish: {
      type: Function,
      default: undefined,
    },
    onProgress: {
      type: Function,
      default: () => ({}),
    },
    onSuccess: {
      type: Function,
      default: undefined,
    },
    blockDefaultNotifications: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    const {
      getHttp,
    } = AHttpAPI();

    const {
      addNotification,
    } = NotificationAPI();

    return {
      addNotification,
      getHttp,
    };
  },
  data() {
    return {
      timeout: undefined,
      status: {
        loading: true,
      },
      taskLog: undefined,
      updateTime: 1000,
    };
  },
  computed: {
    localShowMsg() {
      return get(this.taskLog, "tl_data.msg") && this.showMsg;
    },

    localShowData() {
      return get(this.taskLog, "tl_data");
    },

    isSuccess() {
      return this.taskLog.tl_status === "SUCCESS" || this.taskLog.tl_status === "PARTIAL_SUCCESS";
    },

    isFailure() {
      return this.taskLog.tl_status === "FAILURE";
    },

    isInProgress() {
      return !(this.isSuccess || this.isFailure);
    },
  },
  watch: {
    task() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.resetTask();
      this.timeout = setTimeout(this.updateTask, this.updateTime);
    }
  },
  created() {
    this.resetTask();
    this.timeout = setTimeout(this.updateTask, this.updateTime);
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
  },
  methods: {
    resetTask() {
      this.taskLog = {
        tl_data: {
          msg: "_MSG_TASK_LOG_EXECUTION_IS_BEING_PREPARED_",
        },
      };
    },

    updateTask() {
      if (!this.task) {
        this.timeout = setTimeout(this.updateTask, this.updateTime);
        return;
      }
      this.getHttp({ url: `tasklogs/${ this.task }/` }).then(
        taskLog => {
          this.taskLog = taskLog;
          if (this.isInProgress) {
            this.onProgress({ tasklog: taskLog, result: taskLog.tl_result });
            this.timeout = setTimeout(this.updateTask, this.updateTime);
          } else {
            clearTimeout(this.timeout);
            let cbHandle; // ???
            if (this.onSuccess && this.isSuccess) {
              if (taskLog.tl_status === "SUCCESS" && !this.blockDefaultNotifications) {
                this.addNotification({ text: "_MSG_TASK_LOG_TASK_COMPLETED_SUCCESSFULLY_" });
              } else if (taskLog.tl_status === "PARTIAL_SUCCESS" && !this.blockDefaultNotifications) {
                this.addNotification({ type: "warning", text: "_MSG_TASK_LOG_TASK_PARTIALLY_COMPLETED_SUCCESSFULLY_" });
              }
              if (this.onSuccess) {
                cbHandle = this.onSuccess({ tasklog: taskLog, result: taskLog.tl_result }); // ???
              }
            } else if (this.onFailure && this.isFailure) {
              if (!this.blockDefaultNotifications) {
                let errorMsg;
                if (isString(taskLog.tl_result.data)) {
                  errorMsg = taskLog.tl_result.data;
                } else if (taskLog?.tl_result?.data?.kommentar) {
                  errorMsg = gettext("_MSG_TASK_LOG_PLEASE_ENTER_A_COMMENT_");
                } else if (isObject(taskLog.tl_result.data) && !taskLog?.tl_result?.data?.error_data) { // error_data used by ObjectValidation
                  let errorsArr = [];
                  forEach(values(taskLog.tl_result.data), el => {
                    if (isArray(el)) {
                      errorsArr = extend(errorsArr, el);
                    } else if (isString(el)) {
                      errorsArr.push(el);
                    }
                  });
                  errorMsg = errorsArr.join("\n");
                }
                if (errorMsg) {
                  errorMsg = `<div><strong>${ errorMsg }</strong></div>`;
                } else {
                  errorMsg = "";
                }
                this.addNotification({
                  type: "error",
                  text: "_MSG_TASK_LOG_ERROR_WITH_SERVER_MESSAGE_{{message}}_",
                  extra: {
                    message: errorMsg,
                  },
                });
              }
              if (this.onFailure) {
                cbHandle = this.onFailure({ tasklog: taskLog, result: taskLog.tl_result });
              }
            }
            if (this.onFinish) {
              if (cbHandle && isFunction(cbHandle.then)) {
                cbHandle.then(() => this.onFinish({ result: taskLog.tl_result }));
              } else {
                this.onFinish({ result: taskLog.tl_result });
              }
            }
          }
        },
        error => {
          this.onError(error.data);
        },
      );
    },
  },
};
