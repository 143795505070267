import {
  computed,
  ref,
  toRef,
} from "vue";

import {
  forEach,
  get,
  sortBy,
} from "lodash-es";

export default function ActionsAPI(props, {
  pks = computed(() => []),
}) {
  const close = toRef(props, "close");
  const rows = toRef(props, "rows");
  const updateTable = toRef(props, "updateTable");
  const workflowfolgen = toRef(props, "workflowfolgen");

  const modalWorkflowMassActionsObj = ref(undefined);
  const multipleActionObjData = ref(undefined);
  const multipleActionObj = ref(undefined);
  const status = ref({
    loadingConfigurator: undefined,
    modal: undefined,
    modalWorkflowMassActions: undefined,
    modalWorkflowMassActionsTaskLog: undefined,
    modalWorkflowMassActionsConfirm: undefined,
  });

  const workflowfolgenSorted = computed(() => {
    return sortBy(workflowfolgen.value, ["wfo_pos"]);
  });

  const onMultipleActionsWorkflow = ({ workflowfolge }) => {
    modalWorkflowMassActionsObj.value = workflowfolge;
    if (modalWorkflowMassActionsObj.value.clientfunktion &&
      modalWorkflowMassActionsObj.value.modulname === "simple") {
      multipleActionObjData.value = {
        objects: pks.value,
        workflowfolge: workflowfolge.pk,
      };
      multipleActionObj.value = {
        label: workflowfolge.wfo_kbez,
        workflowFunction: true,
      };
      status.value.modalWorkflowMassActionsConfirm = true;
    } else {
      status.value.modalWorkflowMassActions = true;
    }
  };


  const dropdownActions = computed(() => {
    const ACTIONS = [];
    forEach(workflowfolgenSorted.value, workflowfolge => {
      ACTIONS.push({
        type: "button",
        text: workflowfolge.wfo_kbez || workflowfolge.label,
        extra: workflowfolge.extra,
        callback: arg => onMultipleActionsWorkflow({ ...arg, workflowfolge }),
      });
    });
    return ACTIONS;
  });

  const closeModalWorkflowMassActionsConfirm = () => {
    status.value.modalWorkflowMassActionsConfirm = false;
  };

  const continueMultipleActionWorkflow = () => {
    status.value.modalWorkflowMassActionsConfirm = false;
    setTimeout(() => {
      status.value.modalWorkflowMassActionsTaskLog = true;
    });
  };


  const cancelMassActions = ({ status } = {}) => {
    closeModalWorkflowMassActionsConfirm();
    multipleActionObj.value = undefined;
    if (status) {
      updateTable.value();
    }
    close.value();
  };

  const continueMultipleAction = async() => {
    await multipleActionObj.value.callback({ rows: rows.value, pks: pks.value });

    cancelMassActions({ status: true });
  };

  const continueMassActions = () => {
    if (get(multipleActionObj.value, "workflowFunction")) {
      continueMultipleActionWorkflow();
    } else {
      continueMultipleAction();
    }
  };

  const closeModalWorkflowMassActions = ({ data, multipleActionObj: _multipleActionObj } = {}) => {
    if (data) {
      multipleActionObjData.value = data;
      multipleActionObj.value = _multipleActionObj;
      status.value.modalWorkflowMassActionsConfirm = true;
    }
    status.value.modalWorkflowMassActions = false;
  };

  const closeModalWorkflowMassActionsTaskLog = () => {
    multipleActionObjData.value = undefined;
    cancelMassActions();
    status.value.modalWorkflowMassActionsTaskLog = false;
    updateTable.value();
  };

  return {
    closeModalWorkflowMassActions,
    closeModalWorkflowMassActionsConfirm,
    closeModalWorkflowMassActionsTaskLog,
    continueMassActions,
    dropdownActions,
    modalWorkflowMassActionsObj,
    multipleActionObj,
    multipleActionObjData,
    status,
  };
}
