// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 992px) {
.the_navbar_center {
        font-size: 20px;
}
.the_navbar_left,
    .the_navbar_right {
        white-space: nowrap;
}
}
@media (min-width: 1200px) {
.the_navbar_center {
        font-size: 25px;
}
}
.a_mobile {
.the_navbar_right {
        display: flex;
        align-items: center;
}
.a_menu_2__btn_toggle_outside_menu {
        position: fixed;
        right: 15px;
}
}
.the_navbar_right__child_mobile {
    display: flex;
    align-items: center;
    padding-right: 40px;
}`, "",{"version":3,"sources":["webpack://./client/src/SingleUsageComponents/TheNavbar/TheNavbar.css"],"names":[],"mappings":"AAAA;AACA;QACQ,eAAe;AACvB;AACA;;QAEQ,mBAAmB;AAC3B;AACA;AACA;AACA;QACQ,eAAe;AACvB;AACA;AACA;AACA;QACQ,aAAa;QACb,mBAAmB;AAC3B;AACA;QACQ,eAAe;QACf,WAAW;AACnB;AACA;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":["@media (min-width: 992px) {\n.the_navbar_center {\n        font-size: 20px;\n}\n.the_navbar_left,\n    .the_navbar_right {\n        white-space: nowrap;\n}\n}\n@media (min-width: 1200px) {\n.the_navbar_center {\n        font-size: 25px;\n}\n}\n.a_mobile {\n.the_navbar_right {\n        display: flex;\n        align-items: center;\n}\n.a_menu_2__btn_toggle_outside_menu {\n        position: fixed;\n        right: 15px;\n}\n}\n.the_navbar_right__child_mobile {\n    display: flex;\n    align-items: center;\n    padding-right: 40px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
