import {
  ref,
} from "vue";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";
import ATranslationAPI from "aloha-vue/src/ATranslation/compositionAPI/ATranslationAPI";

import allLanguages from "aloha-vue/src/i18n/allLanguages";
import {
  forEach,
} from "lodash-es";

export default function TranslateAPI() {
  const translationObject = ref({});

  const {
    getHttp,
  } = AHttpAPI();

  const {
    setI18n,
    updateTranslation,
  } = ATranslationAPI();

  const groupTranslateByLang = response => {
    const GROUP_TRANSLATE = {
      "de-de": allLanguages.de,
    };
    forEach(response, item => {
      if (!GROUP_TRANSLATE[item.ubs_sprache]) {
        GROUP_TRANSLATE[item.ubs_sprache] = {};
      }
      GROUP_TRANSLATE[item.ubs_sprache][item.ubs_text] = item.ubs_uebersetzung;
    });
    return GROUP_TRANSLATE;
  };

  const setTranslation = response => {
    setI18n(groupTranslateByLang(response));
  };

  const loadTranslate = () => {
    return getHttp({
      url: "katalog/",
      urlParams: {
        key: "uebersetzungen",
      },
    }).then(
      response => {
        setTranslation(response);
        updateTranslation();
      }
    );
  };

  return {
    loadTranslate,
    translationObject,
  };
}
