import { toFormElementFromParameter } from "../../../../global/functions/mappingForParameterToFormElement";

export default {
  data() {
    return {
      jsonParam: {
        bez: "Zuschuss-Konfiguration pro Periode",
        default: null,
        help_text: null,
        readonly: false,
        options: {
          allow_null: false,
          required: true
        },
        typ: "validated_json",
        many: true,
        as_json: false,
        unique_fields: [],
        key_field: null,
        pos_field: null,
        min_length: null,
        max_length: null,
        element_label: "Element",
        element_template: "{{bezeichnung}}",
        element_errors: true,
        deactivate_ordering: false,
        deactivate_preview: false,
        hide_pos: false,
        fields: {
          bezeichnung: {
            prio: 0,
            bez: "Periode: Bezeichnung",
            default: [
              "{periode}"
            ],
            help_text: null,
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              max_length: null,
              min_length: null
            },
            typ: "string"
          },
          zuschussrechner: {
            prio: 1,
            bez: "Periode: Zuwendungsrechner",
            default: null,
            help_text: null,
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              displaymode: "select2"
            },
            typ: "single_choice",
            katalog: "zuschussrechner",
            katalog_filter: {
              foerdermodule: [
                "standard_zuschuss"
              ]
            }
          },
          max_kosten: {
            prio: 2,
            bez: "Periode: Maximale Gesamtkosten in €",
            default: null,
            help_text: null,
            readonly: false,
            options: {
              allow_null: true,
              required: false,
              max_value: 100000000,
              min_value: 0,
              decimal_places: 2,
              max_digits: 11
            },
            typ: "decimal"
          },
          max_ff_kosten: {
            prio: 3,
            bez: "Periode: Max. förderfähige Kosten für Zuwendungsberechnung in €",
            default: null,
            help_text: null,
            readonly: false,
            options: {
              allow_null: true,
              required: false,
              max_value: 100000000,
              min_value: 1,
              decimal_places: 2,
              max_digits: 11
            },
            typ: "decimal"
          },
          max_foerdersatz: {
            prio: 4,
            bez: "Periode: Maximaler Fördersatz in %",
            default: null,
            help_text: null,
            readonly: false,
            options: {
              allow_null: true,
              required: false,
              max_value: 100,
              min_value: 1,
              decimal_places: 2,
              max_digits: 5
            },
            typ: "decimal"
          },
          max_foerdersumme: {
            prio: 5,
            bez: "Periode: Förderhöchstsumme in €",
            default: null,
            help_text: null,
            readonly: false,
            options: {
              allow_null: true,
              required: false,
              max_value: 100000000,
              min_value: 1,
              decimal_places: 2,
              max_digits: 11
            },
            typ: "decimal"
          },
          min_foerdersumme: {
            prio: 6,
            bez: "Periode: Fördermindestsumme in €",
            default: null,
            help_text: null,
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              max_value: 100000000,
              min_value: 0,
              decimal_places: 2,
              max_digits: 11
            },
            typ: "decimal"
          },
          vergl_zuschuss: {
            prio: 7,
            bez: "Periode: Zuwendung prüfen gegen Bewilligung",
            default: true,
            help_text: null,
            readonly: false,
            options: {
              allow_null: false,
              required: true
            },
            typ: "boolean"
          },
          vergl_prozent_zuschuss: {
            prio: 8,
            bez: "Periode: Zuwendung-Prozente prüfen gegen Bewilligung",
            default: true,
            help_text: null,
            readonly: false,
            options: {
              allow_null: false,
              required: true
            },
            typ: "boolean"
          },
          vergl_prozent_eigenmittel: {
            prio: 9,
            bez: "Periode: Eigenmittel-Prozente prüfen gegen Bewilligung",
            default: false,
            help_text: null,
            readonly: false,
            options: {
              allow_null: false,
              required: true
            },
            typ: "boolean"
          },
          return_if_invalid: {
            prio: 10,
            bez: "Berechnung abbrechen, wenn eine Prüfung invalide ist",
            default: true,
            help_text: "_TXT_GR_PERIODEN_ZUSCHUSS_PARAM_RETURN_IF_INVALID_HELPTEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true
            },
            typ: "boolean"
          },
          add_v_zuschuss_as_limit: {
            prio: 11,
            bez: "Zuschuss auf Bewilligung einschränken",
            default: false,
            help_text: "_TXT_GR_PERIODEN_ZUSCHUSS_PARAM_ADD_VZUSCHUSS_AS_LIMIT_HELP_TEXT_",
            readonly: false,
            options: {
              allow_null: false,
              required: true
            },
            typ: "boolean"
          }
        },
        prio: 5,
        wert: null
      }
    };
  },
  computed: {
    jsonOptions() {
      return toFormElementFromParameter({
        obj: { dimensionen: this.jsonParam },
        showLabel: true,
        staticOptions: {
          required: this.options.required,
          options: this.options.options
        }
      }).list[0];
    }
  }
};
