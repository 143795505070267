export default {
  text: {
    label: "Text",
    options: {
      label: {
        required: true,
      },
      readonly: {
        required: false,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      pdf_width: {
        required: false,
      },
      pdf_header_alignment: {
        required: false
      },
      max_length: {
        required: false,
      },
    },
  },
  // email: {
  //   label: "E-Mail",
  //   options: {
  //     label: {
  //       required: true,
  //     },
  //     required: {
  //       required: true,
  //     },
  //     styles: {
  //       required: false,
  //     },
  //     maxlength: {
  //       required: false,
  //     },
  //   },
  // },
  integer: {
    label: "Ganzzahl",
    options: {
      label: {
        required: true,
      },
      readonly: {
        required: false,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      pdf_width: {
        required: false,
      },
      pdf_header_alignment: {
        required: false
      },
      min_value: {
        required: false,
      },
      max_value: {
        required: false,
      },
      validation_expression: {
        required: false,
      },
      validation_error_message: {
        required: false,
      },
      sum: {
        required: false,
      },
    },
  },
  decimal: {
    label: "Kommazahl",
    options: {
      label: {
        required: true,
      },
      readonly: {
        required: false,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      pdf_width: {
        required: false,
      },
      pdf_header_alignment: {
        required: false
      },
      max_digits: {
        required: true,
      },
      decimal_places: {
        required: true,
      },
      min_value: {
        required: false,
      },
      max_value: {
        required: false,
      },
      validation_expression: {
        required: false,
      },
      validation_error_message: {
        required: false,
      },
      sum: {
        required: false,
      },
    },
  },
  currency: {
    label: "Währung",
    options: {
      label: {
        required: true,
      },
      readonly: {
        required: false,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      pdf_width: {
        required: false,
      },
      pdf_header_alignment: {
        required: false
      },
      max_digits: {
        required: true,
      },
      decimal_places: {
        required: true,
      },
      min_value: {
        required: false,
      },
      max_value: {
        required: false,
      },
      validation_expression: {
        required: false,
      },
      validation_error_message: {
        required: false,
      },
      sum: {
        required: false,
      },
    },
  },
  // textarea: {
  //   label: "Textarea",
  //   options: {
  //     label: {
  //       required: true,
  //     },
  //     required: {
  //       required: true,
  //     },
  //     styles: {
  //       required: false,
  //     },
  //     maxlength: {
  //       required: false,
  //     },
  //   },
  // },
  single_choice: {
    label: "Auswahlliste",
    options: {
      label: {
        required: true,
      },
      readonly: {
        required: false,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      pdf_width: {
        required: false,
      },
      pdf_header_alignment: {
        required: false
      },
      katalog: {
        required: true,
      },
      komplett: {
        required: true,
      },
      searchGlobal: {
        requires: false
      },
      searchParameter: {
        requires: false
      },
      ids: {
        required: true,
      },
    },
  },
  multi_select: {
    label: "Mehrfachauswahl",
    options: {
      label: {
        required: true,
      },
      readonly: {
        required: false,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      pdf_width: {
        required: false,
      },
      pdf_header_alignment: {
        required: false
      },
      katalog: {
        required: true,
      },
      komplett: {
        required: true,
      },
      searchGlobal: {
        requires: false
      },
      searchParameter: {
        requires: false
      },
      ids: {
        required: true,
      },
    },
  },
  single_choice_objektkatalog: {
    label: "Objektbezogene Auswahlliste",
    options: {
      label: {
        required: true,
      },
      readonly: {
        required: false,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      pdf_width: {
        required: false,
      },
      pdf_header_alignment: {
        required: false
      },
      objektkatalog: {
        required: true,
      },
    },
  },
  date: {
    label: "Datum",
    options: {
      label: {
        required: true,
      },
      readonly: {
        required: false,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      pdf_width: {
        required: false,
      },
      pdf_header_alignment: {
        required: false
      },
      validation_expression: {
        required: false,
      },
      validation_error_message: {
        required: false,
      },
    },
  },
  date_expression: {
    label: "Berechnetes Datum",
    options: {
      label: {
        required: true,
      },
      required: {
        required: true,
      },
      berechnung_expression: {
        required: true,
      },
      styles: {
        required: false,
      },
      pdf_width: {
        required: false,
      },
      pdf_header_alignment: {
        required: false
      },
    },
  },
  // daterange: {
  //   label: "Zeitraum",
  //   options: {
  //     label: {
  //       required: true,
  //     },
  //     required: {
  //       required: true,
  //     },
  //     styles: {
  //       required: false,
  //     },
  //   },
  // },
  richtext: {
    label: "Text mit Editor",
    options: {
      label: {
        required: true,
      },
      readonly: {
        required: false,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      pdf_width: {
        required: false,
      },
      pdf_header_alignment: {
        required: false
      },
      max_length: {
        required: false,
      },
    },
  },
  // checkbox: {
  //     label: true,
  //     required: true,
  //     styles: true,
  //   },
  // radio: {
  //     label: true,
  //     required: true,
  //     styles: true,
  //   },
  boolean: {
    label: "Boolean",
    options: {
      label: {
        required: true,
      },
      readonly: {
        required: false,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      pdf_width: {
        required: false,
      },
      pdf_header_alignment: {
        required: false
      },
      anzeigemodus: {
        required: false
      },
      trueLabel: {
        required: false,
      },
      falseLabel: {
        required: false,
      },
      defaultValue: {
        required: true,
      },
    },
  },
  document: {
    label: "Dokument",
    options: {
      label: {
        required: true,
      },
      readonly: {
        required: false,
      },
      required: {
        required: true,
      },
      show_mime_type: {
        required: true,
      },
      buttonText: {
        required: true,
      },
      styles: {
        required: false,
      },
      pdf_width: {
        required: false,
      },
      pdf_header_alignment: {
        required: false
      },
      dokart: {
        required: true,
      },
      isPreview: {
        required: false,
      },
      allowed_mime_type: {
        required: true,
      },
    },
  },
  math_expression: {
    label: "Berechnungsformel",
    options: {
      label: {
        required: true,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      pdf_width: {
        required: false,
      },
      pdf_header_alignment: {
        required: false
      },
      expression: {
        required: true,
      },
      max_digits: {
        required: true,
      },
      decimal_places: {
        required: true,
      },
      min_value: {
        required: false,
      },
      max_value: {
        required: false,
      },
      validation_expression: {
        required: false,
      },
      validation_error_message: {
        required: false,
      },
      sum: {
        required: false,
      },
    },
  },
  decimal_signal: {
    label: "Dezimalzahl durch Signalempfang",
    options: {
      label: {
        required: true,
      },
      required: {
        required: true,
      },
      styles: {
        required: false,
      },
      pdf_width: {
        required: false,
      },
      pdf_header_alignment: {
        required: false
      },
      signal_regex: {
        required: true,
      },
      signal_filter: {
        required: false,
      },
      max_digits: {
        required: true,
      },
      decimal_places: {
        required: true,
      },
      min_value: {
        required: false,
      },
      max_value: {
        required: false,
      },
    },
  },
  decimal_katalog_data: {
    label: "Dezimalzahl aus ausgewähltem Katalogeintrag",
    options: {
      label: {
        required: true,
      },
      styles: {
        required: false,
      },
      pdf_width: {
        required: false,
      },
      pdf_header_alignment: {
        required: false
      },
      max_digits: {
        required: true,
      },
      decimal_places: {
        required: true,
      },
      katalog_spalte_id: {
        required: true,
      },
      katalog_data_id: {
        required: true
      },
      sum: {
        required: false,
      },
      hide: {
        required: false,
      },
    },
  },
  text_katalog_data: {
    label: "Text aus ausgewähltem Katalogeintrag",
    options: {
      label: {
        required: true,
      },
      styles: {
        required: false,
      },
      pdf_width: {
        required: false,
      },
      pdf_header_alignment: {
        required: false
      },
      max_length: {
        required: true,
      },
      katalog_spalte_id: {
        required: true,
      },
      katalog_data_id: {
        required: true
      },
      hide: {
        required: false,
      },
    },
  },
  decimal_objektkatalog_data: {
    label: "Dezimalzahl aus ausgewähltem objektbezogenen Element",
    options: {
      label: {
        required: true,
      },
      styles: {
        required: false,
      },
      pdf_width: {
        required: false,
      },
      pdf_header_alignment: {
        required: false
      },
      max_digits: {
        required: true,
      },
      decimal_places: {
        required: true,
      },
      objektkatalog_spalte_id: {
        required: true,
      },
      objektkatalog_data_id: {
        required: true
      },
      sum: {
        required: false,
      },
      hide: {
        required: false,
      },
    },
  },
  text_objektkatalog_data: {
    label: "Text aus ausgewähltem objektbezogenen Element",
    options: {
      label: {
        required: true,
      },
      styles: {
        required: false,
      },
      pdf_width: {
        required: false,
      },
      pdf_header_alignment: {
        required: false
      },
      max_length: {
        required: true,
      },
      objektkatalog_spalte_id: {
        required: true,
      },
      objektkatalog_data_id: {
        required: true
      },
      hide: {
        required: false,
      },
    },
  },
};

export const LIST = [
  {
    label: "Text",
    value: "text",
    options: [
      {
        id: "label",
      },
      {
        id: "required",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "max_length",
      },
      {
        id: "id",
      },
      {
        id: "readonly",
      },
    ],
  },
  {
    label: "Ganzzahl",
    value: "integer",
    options: [
      {
        id: "label",
      },
      {
        id: "required",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "min_value_int",
      },
      {
        id: "max_value_int",
      },
      {
        id: "validation_expression",
      },
      {
        id: "validation_error_message",
      },
      {
        id: "sum",
      },
      {
        id: "id",
      },
      {
        id: "readonly",
      },
    ],
  },
  {
    label: "Kommazahl",
    value: "decimal",
    options: [
      {
        id: "label",
      },
      {
        id: "required",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "max_digits",
      },
      {
        id: "decimal_places",
      },
      {
        id: "min_value",
      },
      {
        id: "max_value",
      },
      {
        id: "validation_expression",
      },
      {
        id: "validation_error_message",
      },
      {
        id: "sum",
      },
      {
        id: "id",
      },
      {
        id: "readonly",
      },
    ],
  },
  {
    label: "Währung",
    value: "currency",
    options: [
      {
        id: "label",
      },
      {
        id: "required",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "max_digits",
      },
      {
        id: "decimal_places",
      },
      {
        id: "min_value",
      },
      {
        id: "max_value",
      },
      {
        id: "validation_expression",
      },
      {
        id: "validation_error_message",
      },
      {
        id: "sum",
      },
      {
        id: "id",
      },
      {
        id: "readonly",
      },
    ],
  },
  {
    label: "Auswahlliste",
    value: "single_choice",
    options: [
      {
        id: "label",
      },
      {
        id: "required",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "katalog",
      },
      {
        id: "komplett",
      },
      {
        id: "searchGlobal"
      },
      {
        id: "searchParameter"
      },
      {
        id: "ids",
      },
      {
        id: "id",
      },
      {
        id: "readonly",
      },
    ],
  },
  {
    label: "Mehrfachauswahl",
    value: "multi_select",
    options: [
      {
        id: "label",
      },
      {
        id: "required",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "katalog",
      },
      {
        id: "komplett",
      },
      {
        id: "searchGlobal"
      },
      {
        id: "searchParameter"
      },
      {
        id: "ids",
      },
      {
        id: "id",
      },
      {
        id: "readonly",
      },
    ],
  },
  {
    label: "Objektbezogene Auswahlliste",
    value: "single_choice_objektkatalog",
    options: [
      {
        id: "label",
      },
      {
        id: "required",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "objektkatalog",
      },
      {
        id: "id",
      },
      {
        id: "readonly",
      },
    ],
  },
  {
    label: "Datum",
    value: "date",
    options: [
      {
        id: "label",
      },
      {
        id: "uhrzeit",
      },
      {
        id: "required",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "id",
      },
      {
        id: "validation_expression",
      },
      {
        id: "validation_error_message",
      },
      {
        id: "readonly",
      },
    ],
  },
  {
    label: "Berechnetes Datum",
    value: "date_expression",
    options: [
      {
        id: "label",
      },
      {
        id: "required",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "id",
      },
      {
        id: "berechnung_expression",
      },
    ],
  },
  {
    label: "Text mit Editor",
    value: "richtext",
    options: [
      {
        id: "label",
      },
      {
        id: "required",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "max_length",
      },
      {
        id: "id",
      },
      {
        id: "readonly",
      },
    ],
  },
  {
    label: "Boolean",
    value: "boolean",
    options: [
      {
        id: "label",
      },
      {
        id: "required",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "anzeigemodus",
      },
      {
        id: "trueLabel",
      },
      {
        id: "falseLabel",
      },
      {
        id: "defaultValue",
      },
      {
        id: "id",
      },
      {
        id: "readonly",
      },
    ],
  },
  {
    label: "Dokument",
    value: "document",
    options: [
      {
        id: "label",
      },
      {
        id: "buttonText",
      },
      {
        id: "required",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "dokart",
      },
      {
        id: "isPreview",
      },
      {
        id: "allowed_mime_type",
      },
      {
        id: "show_mime_type",
      },
      {
        id: "id",
      },
      {
        id: "readonly",
      },
    ],
  },
  {
    label: "Berechnungsformel",
    value: "math_expression",
    options: [
      {
        id: "label",
      },
      {
        id: "required",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "expression",
      },
      {
        id: "max_digits",
      },
      {
        id: "decimal_places",
      },
      {
        id: "min_value",
      },
      {
        id: "max_value",
      },
      {
        id: "validation_expression",
      },
      {
        id: "validation_error_message",
      },
      {
        id: "sum",
      },
      {
        id: "hide",
      },
      {
        id: "id",
      },
    ],
  },
  {
    label: "Dezimalzahl durch Signalempfang",
    value: "decimal_signal",
    options: [
      {
        id: "label",
      },
      {
        id: "required",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "signal_regex",
      },
      {
        id: "signal_filter",
      },
      {
        id: "max_digits",
      },
      {
        id: "decimal_places",
      },
      {
        id: "min_value",
      },
      {
        id: "max_value",
      },
      {
        id: "hide",
      },
      {
        id: "id",
      },
    ],
  },
  {
    label: "Dezimalzahl aus ausgewähltem objektbezogenen Element",
    value: "decimal_objektkatalog_data",
    options: [
      {
        id: "label",
      },
      {
        id: "required",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "max_digits",
      },
      {
        id: "decimal_places",
      },
      {
        id: "objektkatalog_spalte_id",
      },
      {
        id: "objektkatalog_data_id",
      },
      {
        id: "sum",
      },
      {
        id: "hide",
      },
      {
        id: "id",
      },
    ],
  },
  {
    label: "Text aus ausgewähltem objektbezogenen Element",
    value: "text_objektkatalog_data",
    options: [
      {
        id: "label",
      },
      {
        id: "required",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "max_length",
      },
      {
        id: "objektkatalog_spalte_id",
      },
      {
        id: "objektkatalog_data_id",
      },
      {
        id: "hide",
      },
      {
        id: "id",
      },
    ],
  },
  {
    label: "Dezimalzahl aus ausgewähltem Katalogeintrag",
    value: "decimal_katalog_data",
    options: [
      {
        id: "label",
      },
      {
        id: "required",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "max_digits",
      },
      {
        id: "decimal_places",
      },
      {
        id: "katalog_spalte_id",
      },
      {
        id: "katalog_data_id",
      },
      {
        id: "sum",
      },
      {
        id: "hide",
      },
      {
        id: "id",
      },
    ],
  },
  {
    label: "Text aus ausgewähltem Katalogeintrag",
    value: "text_katalog_data",
    options: [
      {
        id: "label",
      },
      {
        id: "required",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "max_length",
      },
      {
        id: "katalog_spalte_id",
      },
      {
        id: "katalog_data_id",
      },
      {
        id: "hide",
      },
      {
        id: "id",
      },
    ],
  },
  {
    label: "Benutzer",
    value: "user",
    options: [
      {
        id: "label",
      },
      {
        id: "styles",
      },
      {
        id: "pdf_width",
      },
      {
        id: "pdf_header_alignment",
      },
      {
        id: "id",
      },
    ],
  },
];
