import ADropdown from "aloha-vue/src/ADropdown/ADropdown";
import ALink from "aloha-vue/src/ALink/ALink";
import AMenuButtonToggle from "aloha-vue/src/AMenu2/AMenuButtonToggle/AMenuButtonToggle";
import PuxIcon from "../../global/components/PuxIcon/PuxIcon.vue";
import TheAccessibilityButton from "../TheAccessibilityButton/TheAccessibilityButton.vue";
import TheGlobal from "../TheGlobal/TheGlobal.vue";
import TheMenuUser from "../TheMenuUser/TheMenuUser.vue";
import TheModalTranslate from "../TheModalTranslate/TheModalTranslate.vue";
import TheNavbarSearch from "../TheNavbarSearch/TheNavbarSearch.vue";
import TheSearchGlobal from "../TheSearchGlobal/TheSearchGlobal.vue";

import translate from "../../global/directives/translate";

import ConfigAPI from "./compositionAPI/ConfigAPI";
import MobileAPI from "./compositionAPI/MobileAPI";
import StylesAPI from "./compositionAPI/StylesAPI";

// @vue/component
export default {
  name: "TheNavbar",
  components: {
    ADropdown,
    ALink,
    AMenuButtonToggle,
    PuxIcon,
    TheAccessibilityButton,
    TheModalTranslate,
    TheMenuUser,
    TheNavbarSearch,
    TheSearchGlobal,
    TheGlobal,
  },
  directives: {
    translate
  },
  props: {
    config: {
      type: Object,
      required: false,
      default: undefined,
    },
    isAuthenticated: {
      type: Boolean,
      required: false,
    },
    partMarked: {
      type: String,
      required: false,
      validator: value => ["left", "center", "right"].indexOf(value) !== -1,
      default: undefined,
      info: "Teil markieren",
    },
    isMock: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const {
      configLocalCenter,
      configLocalLeft,
      configLocalRight,
      configLocalRightChildrenFiltered,
    } = ConfigAPI({
      props,
    });

    const {
      markedPartClassCenter,
      markedPartClassLeft,
      markedPartClassRight,
    } = StylesAPI({
      props,
    });

    const {
      actionsFilteredMobile,
      hasActionsMobile,
      idForActionsMobile,
      isLoginLinkMobileVisible,
      isMobileWidth,
      isTheMenuUserMobileVisible,
      loginLinkMobile,
    } = MobileAPI({
      configLocalRightChildrenFiltered,
    });

    return {
      actionsFilteredMobile,
      configLocalCenter,
      configLocalLeft,
      configLocalRight,
      configLocalRightChildrenFiltered,
      hasActionsMobile,
      idForActionsMobile,
      isLoginLinkMobileVisible,
      isMobileWidth,
      isTheMenuUserMobileVisible,
      loginLinkMobile,
      markedPartClassCenter,
      markedPartClassLeft,
      markedPartClassRight,
    };
  },
};
