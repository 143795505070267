import {
  computed,
  ref,
  toRef,
} from "vue";

import {
  toFormElementFromParameter,
} from "../../../../../../global/functions/mappingForParameterToFormElement";

export default function OptionsAPI(props) {
  const options = toRef(props, "options");

  const jsonParam = ref({
    bez: "_LBL_AUSZAHLUNGSPLAN_FIELD_BEZEICHNUNG_",
    default: null,
    help_text: "_TXT_AUSZAHLUNGSPLAN_FIELD_BEZEICHNUNG_HELP_TEXT_",
    readonly: false,
    options: {
      allow_null: true,
      required: false
    },
    typ: "validated_json",
    many: true,
    as_json: false,
    key_field: null,
    pos_field: null,
    min_length: true,
    max_length: null,
    element_label: "Modalität",
    element_template: "{{name}} (id={{_id}})",
    element_errors: true,
    fields: {
      _id: {
        prio: 0,
        bez: "_LBL_MODALITAET_ID_",
        help_text: "_TXT_MODALITAET_ID_HELP_TEXT_",
        readonly: false,
        options: {
          required: true,
          allow_null: false,
        },
        typ: "string",
      },
      name: {
        prio: 10,
        bez: "_LBL_MODALITAET_BEZEICHNUNG_",
        default: null,
        max_length: 60,
        help_text: "_TXT_MODALITAET_BEZEICHNUNG_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: false,
          required: true,
        },
        typ: "string",
      },
      praefix: {
        prio: 20,
        bez: "_LBL_MODALITAET_PREFIX_",
        default: null,
        max_length: 20,
        help_text: "_TXT_MODALITAET_PREFIX_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: false,
          required: false,
        },
        typ: "string"
      },
      letzte: {
        prio: 30,
        bez: "_LBL_MODALITAET_LETZTE_",
        default: false,
        help_text: "_TXT_MODALITAET_LETZTE_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: false,
          required: false,
        },
        typ: "boolean"
      },
      beschreibung: {
        prio: 40,
        max_length: 2000,
        bez: "_LBL_MODALITAET_BESCHREIBUNG_",
        help_text: "_LBL_MODALITAET_BESCHREIBUNG_HELP_TEXT_",
        options: {
          required: false
        },
        typ: "text",
      },
      intern: {
        prio: 50,
        bez: "_LBL_MODALITAET_INTERN_",
        help_text: "_TXT_MODALITAET_INTERN_HELP_TEXT_",
        readonly: false,
        default: false,
        typ: "boolean",
      },
      parallele_aktiv: {
        prio: 60,
        bez: "_LBL_MODALITAET_PARALLELE_AKTIV_",
        help_text: "_LBL_MODALITAET_PARALLELE_AKTIV_HELP_TEXT_",
        readonly: false,
        options: {
          required: false,
        },
        typ: "boolean",
        triState: true,
        default: undefined,
        defaultValue: null,
      },
      min_anzahl: {
        prio: 70,
        bez: "_LBL_MODALITAET_MIN_ANZAHL_",
        help_text: "_TXT_MODALITAET_MIN_ANZAHL_HELP_TEXT_",
        min_value: 0,
        default: 1,
        options: {
          required: false,
        },
        typ: "integer",
      },
      max_anzahl: {
        prio: 80,
        bez: "_LBL_MODALITAET_MAX_ANZAHL_",
        help_text: "_TXT_MODALITAET_MAX_ANZAHL_HELP_TEXT_",
        default: 1,
        min_value: 1,
        options: {
          required: false,
          allow_null: false,
        },
        typ: "integer",
      },
      deaktivieren_wenn_maximale_auszahlung: {
        prio: 90,
        bez: "_LBL_MODALITAET_DEAKTIVIEREN_WENN_MAX_AUSZAHLUNG_",
        help_text: "_TXT_MODALITAET_DEAKTIVIEREN_WENN_MAX_AUSZAHLUNG_HELP_TEXT_",
        readonly: false,
        default: false,
        typ: "boolean",
      },
      nachweistyp: {
        prio: 100,
        bez: "_LBL_NACHWEISTYP_",
        default: "8c996724-c913-412a-bdad-37e96be2264e",
        help_text: "_TXT_NACHWEISTYP_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: true,
          required: true,
          displaymode: "select2"
        },
        typ: "single_choice",
        katalog: "synachweistypen",
      },
      modus: {
        prio: 105,
        bez: "_LBL_MODALITAET_BERECHNUNGSREGEL_",
        default: null,
        help_text: "_TXT_MODALITAET_BERECHNUNGSREGEL_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: true,
          required: true,
          displaymode: "select2"
        },
        typ: "single_choice",
        katalog: "berechnungsmodi",
      },
      min_auszahl: {
        prio: 110,
        bez: "_LBL_MODALITAET_MIN_AUSZAHL_",
        help_text: "_TXT_MODALITAET_MIN_AUSZAHL_HELP_TEXT_",
        max_value: 100,
        min_value: 0,
        max_digits: 10,
        decimal_places: 2,
        options: {
          allow_null: true,
          required: false,
        },
        typ: "decimal",
      },
      max_auszahl: {
        prio: 120,
        bez: "_LBL_MODALITAET_MAX_AUSZAHL_",
        help_text: "_TXT_MODALITAET_MAX_AUSZAHL_HELP_TEXT_",
        default: 100,
        max_value: 100,
        min_value: 0,
        max_digits: 10,
        decimal_places: 2,
        options: {
          required: false,
        },
        typ: "decimal",
      },
      max_gesamt_auszahl: {
        prio: 130,
        bez: "_LBL_MODALITAET_GESAMT_AUSZAHL_",
        help_text: "_TXT_MODALITAET_GESAMT_AUSZAHL_HELP_TEXT_",
        default: 0,
        max_digits: 10,
        decimal_places: 2,
        max_value: 100,
        min_value: 0,
        options: {
          required: false,
        },
        typ: "decimal",
      },
      min_rueckforderung: {
        prio: 140,
        bez: "_LBL_MODALITAET_MIN_RUECKFORDERUNG_",
        help_text: "_TXT_MODALITAET_MIN_RUECKFORDERUNG_HELP_TEXT_",
        max_value: 100,
        min_value: 0,
        max_digits: 10,
        decimal_places: 2,
        options: {
          allow_null: true,
          required: false,
        },
        typ: "decimal",
      },
      max_rueckforderung: {
        prio: 150,
        bez: "_LBL_MODALITAET_MAX_RUECKFORDERUNG_",
        help_text: "_TXT_MODALITAET_MAX_RUECKFORDERUNG_HELP_TEXT_",
        max_value: 100,
        min_value: 0,
        max_digits: 10,
        decimal_places: 2,
        options: {
          allow_null: true,
          required: false,
        },
        typ: "decimal",
      },
      positiver_betrag_erforderlich: {
        prio: 160,
        bez: "_LBL_MODALITAET_POSITIVER_BETRAG_ERFORDERLICH_",
        help_text: "_TXT_MODALITAET_POSITIVER_BETRAG_ERFORDERLICH_HELP_TEXT_",
        readonly: false,
        default: true,
        options: {
          required: false
        },
        typ: "boolean",
      },
      kumulativ: {
        prio: 170,
        bez: "_LBL_MODALITAET_KUMULATIV_",
        help_text: "_TXT_MODALITAET_KUMULATIV_HELP_TEXT_",
        default: true,
        options: {
          required: false,
        },
        typ: "boolean",
      },
      min_beleg: {
        prio: 180,
        bez: "_LBL_MODALITAET_MIN_BELEG_",
        help_text: "_TXT_MODALITAET_MIN_BELEG_HELP_TEXT_",
        min_value: 0,
        max_value: 100,
        decimal_places: 2,
        default: 0,
        options: {
          required: false,
        },
        typ: "decimal",
      },
      anerkannt_is_beantragt: {
        prio: 190,
        bez: "_LBL_MODALITAET_ANERKANNT_GLEICH_BEANTRAGT_",
        help_text: "_TXT_MODALITAET_ANERKANNT_GLEICH_BEANTRAGT_HELP_TEXT_",
        default: false,
        options: {
          required: false,
        },
        typ: "boolean",
      },
      anpassung_unten_absolut: {
        prio: 200,
        bez: "_LBL_MODALITAET_ANPASSUNG_UNTEN_ABSOLUT_",
        help_text: "_TXT_MODALITAET_ANPASSUNG_UNTEN_ABSOLUT_HELP_TEXT_",
        default: 0,
        max_digits: 10,
        decimal_places: 2,
        min_value: 0,
        options: {
          required: false,
        },
        typ: "decimal",
      },
      anpassung_oben_absolut: {
        prio: 210,
        bez: "_LBL_MODALITAET_ANPASSUNG_OBEN_ABSOLUT_",
        help_text: "_TXT_MODALITAET_ANPASSUNG_OBEN_ABSOLUT_HELP_TEXT_",
        default: 0,
        max_digits: 10,
        decimal_places: 2,
        min_value: 0,
        options: {
          required: false,
        },
        typ: "decimal",
      },
      anpassung_unten_prozent: {
        prio: 220,
        bez: "_LBL_MODALITAET_ANPASSUNG_UNTEN_PROZENT_",
        help_text: "_TXT_MODALITAET_ANPASSUNG_UNTEN_PROZENT_HELP_TEXT_",
        default: 0,
        max_digits: 10,
        decimal_places: 2,
        min_value: 0,
        max_value: 100,
        options: {
          required: false,
        },
        typ: "decimal",
      },
      anpassung_oben_prozent: {
        prio: 230,
        bez: "_LBL_MODALITAET_ANPASSUNG_OBEN_PROZENT_",
        help_text: "_TXT_MODALITAET_ANPASSUNG_OBEN_PROZENT_HELP_TEXT_",
        default: 0,
        max_digits: 10,
        decimal_places: 2,
        min_value: 0,
        max_value: 100,
        options: {
          required: false,
        },
        typ: "decimal",
      },
      anpassung_begruenden: {
        prio: 240,
        bez: "_LBL_MODALITAET_ANPASSUNG_BEGRUENDEN_",
        help_text: "_TXT_MODALITAET_ANPASSUNG_BEGRUENDEN_HELP_TEXT_",
        default: false,
        options: {
          required: false,
        },
        typ: "boolean",
      },
      workflow_ast: {
        prio: 250,
        bez: "_LBL_MODALITAET_AST_WORKFLOW_",
        help_text: "_TXT_MODALITAET_AST_WORKFLOW_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: false,
          displaymode: "select2",
        },
        typ: "single_choice",
        katalog: "workflow_auszahlung",
      },
      workflow_sa: {
        prio: 260,
        bez: "_LBL_MODALITAET_SACHBEARBEITER_WORKFLOW_",
        help_text: "_TXT_MODALITAET_SACHBEARBEITER_WORKFLOW_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: false,
          displaymode: "select2",
        },
        typ: "single_choice",
        katalog: "workflow_auszahlung"
      },
      koplfipl_anzeigen: {
        prio: 270,
        bez: "_LBL_MODALITAET_KOPLFIPL_ANZEIGEN_",
        help_text: "_TXT_MODALITAET_KOPLFIPL_ANZEIGEN_HELP_TEXT_",
        readonly: false,
        default: true,
        options: {
          required: false,
          displaymode: "select2",
        },
        typ: "boolean",
      },
      beleg_anzeige: {
        prio: 280,
        bez: "_LBL_MODALITAET_BELEGANZEIGE_",
        help_text: "_TXT_MODALITAET_BELEGANZEIGE_HELP_TEXT_",
        readonly: false,
        options: {
          allow_empty: false,
          displaymode: "select2",
        },
        typ: "single_choice",
        katalog: "auszahlungsbeleg_anzeige",
      },
      bestandsuebersicht: {
        prio: 290,
        bez: "_LBL_MODALITAET_BESTANDSUEBERSICHT_",
        help_text: "_TXT_MODALITAET_BESTANDSUEBERSICHT_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: true,
          displaymode: "select2",
          required: false,
        },
        typ: "single_choice",
        katalog: "foerderregel",
        katalog_filter: { suche: "vertrag_bestand" }
      },
      vertreter_anzeigen: {
        prio: 300,
        bez: "_LBL_MODALITAET_VERTRETER_ANZEIGEN_",
        help_text: "_LBL_MODALITAET_VERTRETER_HELP_TEXT_",
        readonly: false,
        default: true,
        options: {
          required: false,
        },
        typ: "boolean",
      },
      bankverbindung_anzeigen: {
        prio: 310,
        bez: "_LBL_MODALITAET_BANKVERBINDUNG_ANZEIGEN_",
        help_text: "_LBL_MODALITAET_BANKVERBINDUNG_ANZEIGEN_HELP_TEXT_",
        readonly: false,
        default: true,
        options: {
          required: false,
        },
        typ: "boolean",
      },
      bv_contenttypeid: {
        prio: 320,
        bez: "_LBL_MODALITAET_BV_CONTENTTYPE_",
        help_text: "_TXT_MODALITAET_BV_CONTENTTYPE_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: false,
          allow_empty: false,
          required: true,
          displaymode: "select2",
        },
        typ: "single_choice",
        katalog: "bankverbindungstypen",
      },
      sachbericht: {
        prio: 330,
        bez: "_LBL_MODALITAET_SACHBERICHT_",
        help_text: "_TXT_MODALITAET_SACHBERICHT_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: true,
          displaymode: "select2",
          required: false,
        },
        typ: "single_choice",
        katalog: "sachberichtvorlagen",
      },
      pruefbericht: {
        prio: 340,
        bez: "_LBL_MODALITAET_PRUEFBERICHT_",
        help_text: "_TXT_MODALITAET_PRUEFBERICHT_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: true,
          displaymode: "select2",
          required: false,
        },
        typ: "single_choice",
        katalog: "sachberichtvorlagen",
      },
      pflichtdok: {
        prio: 350,
        bez: "_LBL_MODALITAET_PFLICHTDOKUMENTE_",
        help_text: "_TXT_MODALITAET_PFLICHTDOKUMENTE_HELP_TEXT_",
        readonly: false,
        many: true,
        default: () => [],
        options: {
          allow_null: true,
          required: false,
          displaymode: "select2",
          many: true,
        },
        typ: "multiselect",
        katalog: "dokarten",
      },
      pdf_template: {
        prio: 360,
        bez: "_LBL_MODALITAET_PDF_TEMPLATE_",
        help_text: "_TXT_MODALITAET_PDF_TEMPLATE_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: true,
          displaymode: "select2",
          required: false,
        },
        typ: "single_choice",
        katalog: "template"
      },
      auszahl_ab_bezug: {
        prio: 370,
        bez: "_LBL_MODALITAET_BEZUGDATUM_FUEHESTE_AUSZAHLUNG_",
        help_text: "_TXT_MODALITAET_BEZUGDATUM_FUEHESTE_AUSZAHLUNG_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: true,
          allow_empty: false,
          required: true,
          displaymode: "select2",
        },
        typ: "single_choice",
        katalog: "auszahlungdatumsbezug",
      },
      auszahl_ab_bezug_formel: {
        prio: 380,
        typ: "string",
        bez: "_LBL_MODALITAET_AUSZAHLEN_AB_BEZUG_FORMEL_",
        help_text: "_TXT_MODALITAET_AUSZAHLEN_AB_BEZUG_FORMEL_HELP_TEXT_",
        options: {
          required: false,
          allow_null: false,
        },
      },
      auszahl_ab: {
        prio: 390,
        bez: "_LBL_MODALITAET_AUSZAHL_AB_",
        help_text: "_TXT_MODALITAET_AUSZAHL_AB_HELP_TEXT_",
        default: 0,
        options: {
          required: false,
        },
        typ: "integer",
      },
      auszahl_bis_bezug: {
        prio: 400,
        bez: "_LBL_MODALITAET_BEZUGDATUM_SPAETESTE_AUSZAHLUNG_",
        help_text: "_TXT_MODALITAET_BEZUGDATUM_SPAETESTE_AUSZAHLUNG_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: false,
          displaymode: "select2",
        },
        typ: "single_choice",
        katalog: "auszahlungdatumsbezug",
      },
      auszahl_bis_bezug_formel: {
        prio: 410,
        typ: "string",
        bez: "_LBL_MODALITAET_AUSZAHLEN_BIS_BEZUG_FORMEL_",
        help_text: "_TXT_MODALITAET_AUSZAHLEN_BIS_BEZUG_FORMEL_HELP_TEXT_",
        options: {
          required: false,
          allow_null: true,
        },
      },
      auszahl_bis: {
        prio: 420,
        bez: "_LBL_MODALITAET_AUSZAHL_BIS_",
        help_text: "_TXT_MODALITAET_AUSZAHL_BIS_HELP_TEXT_",
        min_value: 0,
        default: 0,
        options: {
          required: false,
        },
        typ: "integer",
      },
      update_from_client: {
        prio: 430,
        bez: "_LBL_MODALITAET_UPDATE_FROM_CLIENT_",
        help_text: "_TXT_MODALITAET_UPDATE_FROM_CLIENT_HELP_TEXT_",
        readonly: false,
        options: {
          required: false,
        },
        typ: "boolean",
        default: undefined,
        defaultValue: null,
      },
    },
    prio: 6,
    wert: null
  });


  const jsonOptions = computed(() => {
    return toFormElementFromParameter({
      obj: { modalitaet: jsonParam.value },
      showLabel: true,
      staticOptions: {
        required: options.value?.required,
        options: options.value?.options
      }
    }).list[0];
  });

  return {
    jsonOptions,
    jsonParam,
  };
}
